import React from 'react'

import Helmet from 'react-helmet'
import { useSiteMetadata } from '../../hooks'
import { withPrefix } from 'gatsby'
import Footer from '../Footer'

const Layout = ({ children, title, description }) => {
  const { siteUrl } = useSiteMetadata()

  // language=JSON
  return (
    <div className="is-fluid">
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{title}</title>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="description" content={description}/>
        {/* social media tags */}
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={siteUrl}/>
        <meta property="og:title" content={title}/>
        <meta property="og:image" content="https://australia.voxxeddays.com/media/social.png"/>
        <meta property="og:site_name" content={title}/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:image:width" content="1000"/>
        <meta property="og:image:height" content="563"/>

        {/*  twitter social tags */}
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:url" content={siteUrl}/>
        <meta name="twitter:site" content="@VoxxedAustralia"/>
        <meta name="twitter:creator" content="@VoxxedAustralia"/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={description}/>
        <meta name="twitter:image" content="https://australia.voxxeddays.com/media/social-twitter.png"/>

        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "${title}",
            "url": "${siteUrl}",
            "description": "${description}"
          }`}
        </script>

        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "${title}",
            "url": "https://australia.voxxeddays.com",
            "description": "${description}"
          }`}
        </script>

        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "${title}",
            "url": "${siteUrl}",
            "sameAs": [
              "https://www.youtube.com/watch?v=6z716b-XMPo",
              "https://www.linkedin.com/company/voxxed-days-australia",
              "https://twitter.com/VoxxedAustralia",
              "https://www.facebook.com/voxxed",
              "https://www.instagram.com/voxxedaustralia"
            ]
          }`}
        </script>

        {/*https://developers.google.com/search/docs/data-types/event*/}
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Event",
            "name": "${title}",
            "startDate": "2022-02-14",
            "location": {
              "@type": "Place",
              "name": "Melbourne",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "123 Collins St",
                "addressLocality": "Melbourne",
                "postalCode": "3000",
                "addressRegion": "VIC",
                "addressCountry": "AU"
              }
            },
            "image": [
              "https://australia.voxxeddays.com/media/social.png",
              "https://australia.voxxeddays.com/media/social-twitter.png"
            ],
            "description": "${description}",
            "endDate": "2022-02-15",
            "offers": {
              "@type": "Offer",
              "url": "https://www.trybooking.com/book/event?eid=629452",
              "priceCurrency": "AUD",
              "price": "199",
              "availability": "http://schema.org/InStock"
            }
          }`}
        </script>

        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Event",
            "name": "${title}",
            "startDate": "2022-02-17",
            "location": {
              "@type": "Place",
              "name": "Sydney",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Level 3/26-32 Pirrama Road",
                "addressLocality": "Pyrmont",
                "postalCode": "2009",
                "addressRegion": "NSW",
                "addressCountry": "AU"
              }
            },
            "image": [
              "https://australia.voxxeddays.com/media/social.png",
              "https://australia.voxxeddays.com/media/social-twitter.png"
            ],
            "description": "${description}",
            "endDate": "2022-02-18",
            "offers": {
              "@type": "Offer",
              "url": "https://www.trybooking.com/book/event?eid=629452",
              "priceCurrency": "AUD",
              "price": "199",
              "availability": "http://schema.org/InStock"
            }
          }`}
        </script>


        {/* scripts - jquery is needed here */}
        <script
          src="https://code.jquery.com/jquery-3.4.1.min.js"
          integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
          crossOrigin="anonymous">
        </script>
        <script src={withPrefix('assets/main.js')}/>
      </Helmet>
      {children}
      <Footer/>
    </div>
  )
}

export default Layout
