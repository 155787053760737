import React from 'react'
import { StaticQuery, graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
const Footer = () => {
  const { sharex, share, site } = useStaticQuery(
    graphql`
      query {
        sharex: file(relativePath: { eq: "voxxed-share-the-knowledge-x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        share: file(relativePath: { eq: "voxxed-share-the-knowledge.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 240) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        site {
            buildTime(formatString: "DD/MM/YYYY")
            year: buildTime(formatString: "YYYY")
          }
      }
    `,
  )

  const sharexImg = sharex.childImageSharp.fluid
  const shareImg = share.childImageSharp.fluid

  return (
    <footer className="has-text-centered">
      <div className={'has-background-dark has-padding-top-30 has-padding-bottom-30'}>
        <div className={'has-padding-bottom-20'}>
          <Img fluid={sharexImg} className={'has-image-centered'} style={{
            maxWidth: '80px',
          }}/>
        </div>
        <div>
          <Img fluid={shareImg} className={'has-image-centered'} style={{
            maxWidth: '240px',
          }}/>
        </div>
      </div>
      <div className={'has-background-black has-text-white has-padding-top-10 social'}>
        <a
          href="https://www.instagram.com/voxxedaustralia/"
          title={'find us on twitter'}
          rel={'voxxeddays australia'}
        >
          <span className="icon is-medium has-text-white">
            <i className="fab fa-twitter-square fa-2x"/>
          </span>
        </a>{' '}
        <a
          href="https://www.linkedin.com/company/voxxed-days-australia/"
          title={'find us on linkedin'}
          rel={'voxxeddays australia'}
        >
          <span className="icon is-medium has-text-white">
            <i className="fab fa-linkedin fa-2x"/>
          </span>
        </a>{' '}
        <a
          href="https://www.instagram.com/voxxedaustralia/"
          title={'find us on instagram'}
          rel={'voxxeddays australia'}
        >
          <span className="icon is-medium has-text-white">
            <i className="fab fa-instagram-square fa-2x"/>
          </span>
        </a>
      </div>
      <p className={'has-background-black has-text-white is-color-white has-padding-bottom-10'}>
        Copyright &copy; {site.year}. All rights reserved.
        <br/>
        <small><Link to={'/privacy-policy'}>Privacy Policy</Link> |{' '}
          <Link to={'/terms-and-conditions'}>Terms & Conditions</Link></small>
        <br/>
        <em><small className={'is-7'}>This independent VOXXED DAYS Event is operated under license from VOXXED Limited.
          It is operated and organised by The Technology Conference Company.</small></em>
        <br/>
        <small style={{ display: 'none' }}>compiled {site.buildTime}</small>
      </p>
    </footer>
  )
}

export default Footer
